body {
  margin: 0;
  font-family: pnova-reg, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f8f8f8; */
  background-color: #f6f9fc;
  color: #232323;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a,
a:visited,
a:hover,
a:active {
  color: inherit;
  text-decoration: none;
}
a:hover {
  color: #6772e5;
}

h1,
h2 {
  font-family: pnova-bold, sans-serif;
}
h3,
h4 {
  font-family: pnova-semibold, sans-serif;
}

@font-face {
  font-family: 'pnova-reg';
  src: url('./assets/fonts/proxima-nova-soft-regular-webfont.eot');
  src: url('./assets/fonts/proxima-nova-soft-regular-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/proxima-nova-soft-regular-webfont.woff') format('woff'),
    url('./assets/fonts/proxima-nova-soft-regular-webfont.ttf')
      format('truetype'),
    url('./assets/fonts/proxima-nova-soft-regular-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'pnova-med';
  src: url('./assets/fonts/proxima-nova-soft-medium-webfont.eot');
  src: url('./assets/fonts/proxima-nova-soft-medium-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/proxima-nova-soft-medium-webfont.woff') format('woff'),
    url('./assets/fonts/proxima-nova-soft-medium-webfont.ttf')
      format('truetype'),
    url('./assets/fonts/proxima-nova-soft-medium-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'pnova-semibold';
  src: url('./assets/fonts/proxima-nova-soft-semibold-webfont.eot');
  src: url('./assets/fonts/proxima-nova-soft-semibold-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/proxima-nova-soft-semibold-webfont.woff') format('woff'),
    url('./assets/fonts/proxima-nova-soft-semibold-webfont.ttf')
      format('truetype'),
    url('./assets/fonts/proxima-nova-soft-semibold-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'pnova-bold';
  src: url('./assets/fonts/proxima-nova-soft-bold-webfont.eot');
  src: url('./assets/fonts/proxima-nova-soft-bold-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('./assets/fonts/proxima-nova-soft-bold-webfont.woff') format('woff'),
    url('./assets/fonts/proxima-nova-soft-bold-webfont.ttf') format('truetype'),
    url('./assets/fonts/proxima-nova-soft-bold-webfont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@keyframes blinker {
  50% {
    opacity: 0.5;
  }
}
